import React from 'react';
import { navigate } from 'gatsby';

const OrderRoute = (props) => {
    const { location, path, redirect, children, Component, ...rest } = props;

    if (location.pathname.slice(-1) === '/') {
        navigate(location.pathname.slice(0, -1), { replace: true });
        return null;
    }

    if (redirect && redirect.from === location.pathname) {
        navigate(redirect.to, { replace: true });
        return null;
    }

    if (Component) {
        return (
            <Component path={path} location={location} {...rest}>
                {children}
            </Component>
        );
    }

    return <>{children}</>;
};

export default OrderRoute;
